import { forwardRef } from "react";
import classNames from "classnames";
import * as classes from "./ButtonIcon.css";

type ButtonProps = {
  children: React.ReactNode;
  onClick?: () => void;
  label?: string;
  className?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  visuallyHidden?: boolean;
  filled?: boolean;
};

export const ButtonIcon = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      onClick = () => {},
      label,
      className = "",
      disabled,
      style = {},
      visuallyHidden,
      filled,
    }: ButtonProps,
    ref
  ) => (
    <button
      ref={ref}
      type="button"
      onClick={onClick}
      aria-label={label}
      className={classNames(classes.button, className, {
        [classes.filled]: filled,
      })}
      aria-hidden={visuallyHidden}
      disabled={disabled}
      style={style}
    >
      {children}
    </button>
  )
);

ButtonIcon.displayName = "ButtonIcon";
