export function ResponsiveImage({
  image,
  mediumMinWidth,
  largeMinWidth,
  className = "",
  fetchPriority = "auto",
  ...imageProps
}: React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> & {
  image?: { small: string; medium: string; large: string } | null;
  mediumMinWidth: number;
  largeMinWidth: number;
  className?: string;
  alt: string;
}) {
  if (!image) {
    return null;
  }

  return (
    <picture>
      <source srcSet={image.large} media={`(min-width: ${largeMinWidth}px)`} />
      <source
        srcSet={image.medium}
        media={`(min-width: ${mediumMinWidth}px)`}
      />
      <img
        {...imageProps}
        // eslint-disable-next-line react/no-unknown-property
        fetchpriority={fetchPriority}
        src={image.small}
        className={className}
        alt={imageProps.alt}
      />
    </picture>
  );
}
